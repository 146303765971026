import { IResolve } from '@/types/communal.types';
import { api } from './api';
import {
  IFilterFields,
  ITableListFields,
  IDriverBaseResolve,
  ArrangeOrderParams,
  IDriverArrangeOrderResolve,
  ArrangeOrderFields,
  IDriverInformationResolve,
  IDriverInformationParams,
  IReviewDriverInformationParams,
  IInviteDriverRegisterParams,
  IDeactivateDriverParams,
  IDriverReviewInformationResolve,
  IDriverIdAndNameResolve,
  ICreateDriverAccountParams,
  IReviewItemDto,
  IGetDriverTypeListDto,
  IUpdateDriverTypeListParams,
  IInsertDriverTypeListParams,
  IUpdateDriverTypeSortingParams,
  IFilterParams,
  ILoadDriverReportCountDto,
  IBatchModifyDriverSchedulingParameterParams,
  IDriverTypeDto,
  IUpdateDriverTypeSimulateRouteParams,
} from '@/types/driver.types';
import { IUpdateResolve, IPageListResolve, IDetailResolve } from '@/types/table.base.types';
import { FilterEmptyData } from '@/utils/params';

// 獲取司機列表
export const OnGetList = async (params: IFilterFields | undefined): Promise<IPageListResolve<ITableListFields>> => {
  return await api.get('/driver/LoadDriverReport', { params: FilterEmptyData<IFilterFields | undefined>(params) });
};

/**
 * 获取符合条件的排单参数数量及ID
 * @param params
 * @returns
 */
export const loadDriverReportCount = async (params: IFilterParams): Promise<IResolve<ILoadDriverReportCountDto>> => {
  return await api.get('/driver/LoadDriverReportCount', { params: FilterEmptyData<IFilterParams | undefined>(params) });
};

/**
 * 批量修改排单参数
 * @param params
 * @returns
 */
export const batchModifyDriverSchedulingParameter = async (params: IBatchModifyDriverSchedulingParameterParams): Promise<IUpdateResolve> => {
  return await api.post('/driver/BatchModifyDriverSchedulingParameter', params);
};

// 獲取司機明細
export const GetDriverItem = async (params: { DriverId: string }): Promise<IDriverBaseResolve> => {
  return await api.get('/driver/QueryDriverBaseInfo', { params });
};

// 獲取排單明細
export const GetArrangeOrder = async (params: ArrangeOrderParams): Promise<IDriverArrangeOrderResolve> => {
  return await api.get('/driver/QueryDriverSchedulingParameter', { params });
};

// 提交排單參數
export const UpdateArrangeOrder = async (params: ArrangeOrderFields): Promise<IUpdateResolve> => {
  return await api.post('/driver/InsertUpdateDriverSchedulingParameter', { ...params });
};

// 導入司機信息
export const ImportDriverReport = async (params: { file: File }): Promise<IUpdateResolve> => {
  return await api.postForm('/driver/ImportDriverReport', params);
};

// 獲取司機提交的審核資料
export const GetDriverInformation = async (params: IDriverInformationParams): Promise<IDetailResolve<IDriverInformationResolve[]>> => {
  return await api.get('/driver/ReviewDriverInformation', { params });
};

// 查看已經審核通過的個人資料
export const GetDriverReviewInformation = async (params: IDriverInformationParams): Promise<IDetailResolve<IDriverReviewInformationResolve>> => {
  return await api.post('/driver/GetDriverReviewInformation', params);
};

// 提交司機提交的審核資料
export const PostDriverInformation = async (params: IReviewDriverInformationParams): Promise<IUpdateResolve> => {
  return await api.post('/driver/ReviewDriverInformation', { ...params });
};

// 邀請司機注冊信息Excel導入接口
export const ImportInviteDriverExcelReport = async <T>(params: { file: File }): Promise<IDetailResolve<T>> => {
  return await api.postForm('/driver/ImportInviteDriverExcelReport', params);
};

// 给司机发送邀请短信
export const InviteDriverRegister = async (params: IInviteDriverRegisterParams[]): Promise<IDetailResolve<number>> => {
  return await api.post('/driver/InviteDriverRegister', { importInviteDriverReportDtos: params });
};

// 获取当前公司是否开启司机邀请功能
export const GetDriverInvited = async (): Promise<IDetailResolve<boolean>> => {
  return await api.get('/driver/DriverInvited/Setting');
};

// 停用司机
export const deactivateDriver = async (params: IDeactivateDriverParams): Promise<IResolve<null>> => {
  return await api.post('/driver/enableDriver', params);
};

// 获取司机 id 和 name 的列表，只获取已加入、待审核、已绑定 的状态司机
export const getDriverIdAndNameList = async (): Promise<IDriverIdAndNameResolve> => {
  return await api.get('/driver/LoadDriverIdAndNameList');
};

//  司機編號編輯接口
export const updateDriverNumberApi = async (params: {driverId: string, driverNumber: string}): Promise<IResolve<null>> => {
  return await api.put('/driver/UpdateDriverNumber', params);
};

/**
 * 小後臺手動創建司機賬號 /driver/CreateDriverAccount
 */
export const createDriverAccount = async (data: ICreateDriverAccountParams): Promise<IResolve<null>> => {
  return await api.post('/driver/CreateDriverAccount', data);
};

// 获取司机个人资料Item默认项
export const getDriverReviewItemDefault = async (): Promise<IResolve<IReviewItemDto[]>> => {
  return await api.get('/driver/GetDriverReviewItemDefault');
};

// 获取司机类型列表
export const getDriverTypeList = async (): Promise<IResolve<IGetDriverTypeListDto[]>> => {
  return await api.get('/driver/GetDriverTypeList');
};

// 添加司机类型信息
export const insertDriverTypes = async (params: IInsertDriverTypeListParams[]): Promise<IResolve<null>> => {
  return await api.post('/driver/InsertDriverTypes', { Data: params });
};

// 更新司机类型信息
export const updateDriverTypes = async (params: IUpdateDriverTypeListParams[]): Promise<IResolve<null>> => {
  return await api.post('/driver/UpdateDriverTypes', { Data: params });
};

// 删除司机类型信息
export const deleteDriverTypes = async (DriverTypeIds: string[]): Promise<IResolve<null>> => {
  return await api.delete('/driver/DeleteDriverTypes', {
    data: { DriverTypeIds },
  });
};

// 更改司机类型顺序
export const updateDriverTypesSorting = async (params: IUpdateDriverTypeSortingParams[]): Promise<IResolve<null>> => {
  return await api.post('/driver/UpdateDriverTypesSorting', { Data: params });
};

/** 更改司机类型模拟配送路线 */
export const updateDriverTypeSimulateRouting = async (params: IUpdateDriverTypeSimulateRouteParams): Promise<IResolve<null>> => {
  return await api.post('/driver/UpdateDriverTypeSimulateRouting', params);
};

/**
 * 获取當前用戶組織下的所有司機類型信息
 * @returns
 */
export const getDriverTypeInfo = async (): Promise<IResolve<IDriverTypeDto[]>> => {
  return await api.get('/driver/GetDriverTypeInfo');
};

/**
 * 获取美国所有的城市名称
 * @returns
 */
export const getAllCityEnName = async (): Promise<IResolve<{CityEnNameList: string[]}>> => {
  return await api.get('/driver/GetAllCityEnName');
};
