import { api } from './api';
import { FilterEmptyData } from '@/utils/params';
import { IDetailResolve, IOtherResolve, IPageListResolve, IUpdateResolve } from '@/types/table.base.types';
import { IBusinessSettingParams, IBusinessSettingResolve, IClientApiKeyDataResolve, ICreateClientApiKeyParams, ICreateClientApiKeyResolve, IDriverReviewSetting, IDriverReviewSettingItem, IEvaluateSetting, IEvaluateSettingParams, IFilterFields, INoticeSettingParams, INoticeSettingResolve, INoticeTypeDto, ITableListFields, IPlanTypeDto, IPlanTypeParams, IRollSettingParams, IRollSettingDto, IPlanningPriorityDto, IPlanningPriorityParams, IDriverRegisterSettingParams, IDriverRegisterSettingResolve } from '@/types/notice.types';
import { IResolve } from '@/types/communal.types';
import { IFormSubmitPathSettingParams, IGetFormSubmitPathSettingResolve, IOtherSettingFormParams, IOtherSettingFormResolve, IPrivacySettingForm, IPrivacySettingResolve } from '@/types/other.setting.types';

// 通知列表
export const OnGetList = async (params?: IFilterFields): Promise<IPageListResolve<ITableListFields>> => {
  return await api.get('/Notice', { params: FilterEmptyData<IFilterFields | undefined>(params) });
};

// 获取未读
export const GetNoticeUnRead = async (): Promise<IResolve<number>> => {
  return await api.get('/Notice/unReadCount');
};

// 設置已讀
export const OnFormUpdate = async (id: string): Promise<IUpdateResolve> => {
  return await api.put(`/Notice/${id}/setRead`);
};

// 获取通知设置详情
export const GetNoticeSetting = async (): Promise<INoticeSettingResolve> => {
  return await api.get('/Notice/settings');
};

// 通知设置
export const OnNoticeSetting = async (params: INoticeSettingParams): Promise<IOtherResolve> => {
  return await api.post('/Notice/settings', params);
};

// 業務設置
// 获取业务设置详情
export const getBusinessSetting = async (): Promise<IBusinessSettingResolve> => {
  return await api.get('/Notice/BusinessSetting');
};
// 业务设置
export const updateBusinessSetting = async (params: IBusinessSettingParams): Promise<IOtherResolve> => {
  return await api.post('/Notice/BusinessSetting', params);
};

// 接口管理
// 获取client apikey
export const getClientApikey = async (): Promise<IClientApiKeyDataResolve> => {
  return await api.get('/client');
};
export const deleteClientApikey = async (clientId: string): Promise<IOtherResolve> => {
  return await api.delete('/client/DeleteClient', {
    data: {
      id: clientId,
    },
  });
};
export const createClientApikey = async (data: ICreateClientApiKeyParams): Promise<ICreateClientApiKeyResolve> => {
  return await api.post('/client/client', data);
};

// 獲取司機資料要求信息
export const getDriverReviewSetting = async (): Promise<IDetailResolve<IDriverReviewSettingItem[]>> => {
  return await api.get('/Notice/DriverReviewSetting');
};

// 保存司機資料要求信息
export const saveDriverReviewSetting = async (params: IDriverReviewSetting): Promise<IUpdateResolve> => {
  return await api.post('/Notice/DriverReviewSetting', params);
};

// 批量设置已讀
export const OnBatchRead = async (noticeIds: string[]): Promise<IUpdateResolve> => {
  return await api.post('/Notice/batchRead', { noticeIds });
};

// 其他设置
export const GetOtherSetting = async (): Promise<IOtherSettingFormResolve> => {
  return await api.get('/Notice/otherSetting');
};

export const OnOtherSetting = async (params: IOtherSettingFormParams): Promise<IOtherResolve> => {
  return await api.post('/Notice/otherSetting', params);
};

// 获取评价设置
export const getEvaluateSetting = async (): Promise<IDetailResolve<IEvaluateSetting>> => {
  return await api.get('/Notice/evaluateSetting');
};
// 保存评价设置
export const saveEvaluateSetting = async (params: IEvaluateSettingParams): Promise<IUpdateResolve> => {
  return await api.post('/Notice/evaluateSetting', params);
};
/**
 * 获取所有消息類型
 */
export const getNoticeTypeList = async (): Promise<IResolve<INoticeTypeDto[]>> => {
  return await api.get('/Notice/GetAllNoticeType');
};

// 获取规划方式
export const getPlanType = async (): Promise<IResolve<IPlanTypeDto>> => {
  return await api.get('/routing/GetCurrentPlanningType');
};

// 设置规划方式
export const postPlanType = async (params: IPlanTypeParams): Promise<IResolve<null>> => {
  return await api.post('/routing/UpdateCurrentPlanningType', params);
};

// 獲取隱私設置
export const getPrivateSettings = async (): Promise<IPrivacySettingResolve> => {
  return await api.get('/Notice/privacySettings');
};

// 設置隱私設置
export const postPrivacySetting = async (params: IPrivacySettingForm):  Promise<IResolve<null>> => {
  return await api.post('/Notice/privacySettings', params);
};

/**
 * 设置消息滚动设置
 */
export const postRollSetting = async (params: IRollSettingParams):  Promise<IResolve<null>> => {
  return await api.post('/User/rollSetting', params);
};

/**
 * 获取消息滚动设置
 */
export const getRollSetting = async ():  Promise<IResolve<IRollSettingDto | null>> => {
  return await api.get('/User/rollSetting');
};

/**
 * 获取發佈時間小於24小時的未讀的重要通知
 */
export const getRollNotice = async ():  Promise<IResolve<ITableListFields[]>> => {
  return await api.get('/Notice/getRollNotice');
};

/**
 * 获取当前组织路线规划优先级
 * @returns
 */
export const getPlanPriority = async (): Promise<IResolve<IPlanningPriorityDto>> => {
  return await api.get('/Notice/RoutingPlanningPriority');
};

/**
 * 更改当前组织路线规划优先级
 * @param params
 * @returns
 */
export const postPlanPriority = async (params: IPlanningPriorityParams): Promise<IResolve<null>> => {
  return await api.post('/Notice/RoutingPlanningPriority', null, {
    params,
  });
};

/**
 * 设置司机注册需要填写的内容
 * @param data
 * @returns
 */
export const postDriverRegisterSetting = async (data: IDriverRegisterSettingParams): Promise<IResolve<null>> => {
  return await api.post('/Notice/setDriverRegisterSetting', data);
};

/**
 * 获取司机注册需要填写的内容
 * @returns
 */
export const getDriverRegisterSetting = async (params: {InviteId?:string, OrganizationId?: string}): Promise<IDriverRegisterSettingResolve> => {
  return await api.get('/Notice/getDriverRegisterSetting', {
    params,
  });
};
// 表单提交路径设置
export const getFormSubmitPathSettingApi = async (): Promise<IGetFormSubmitPathSettingResolve> => {
  return await api.get('/Notice/FormSubmitPathSetting');
};

export const postFormSubmitPathSettingApi = async (params: IFormSubmitPathSettingParams): Promise<IOtherResolve> => {
  return await api.post('/Notice/FormSubmitPathSetting', params);
};
