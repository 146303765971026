import React                  from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Breadcrumbs            from '@mui/material/Breadcrumbs';
import Link                   from '@mui/material/Link';
import Typography             from '@mui/material/Typography';
import { useBreadcrumb }      from '../breadcrumb/hooks';
import { LinkRouterProps }    from './types';

// eslint-disable-next-line no-unused-vars
const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any}/>
);

export const Breadcrumb = () => {
  const { breadcrumb } = useBreadcrumb();

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{
      padding: '12px 10px',
      backgroundColor: 'background.paper',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    }}>
      {
        breadcrumb.map((item, key: number) => {
          // const elm = key !== breadcrumb.length - 1
          //   ? <LinkRouter underline="hover" color="inherit" key={key} to={item.path as string}>{item.title}</LinkRouter>
          //   : <Typography key={key} color="text.primary" variant="inherit">{item.title}</Typography>;
          return <Typography key={key} color="text.primary" variant="inherit">{item.title}</Typography>;
        })
      }
    </Breadcrumbs>
  );
};
